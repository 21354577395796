<template>
  <div>
    <div>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">News/Promotion News Detail</h1>
        </div>
        <div class="title-tabs mt-3">
          <div class="content-between">
            Promotion Info
            <InputSelectLanguage ref="inputLang" @changeLang="changeLanguage" />
          </div>
        </div>
        <div v-if="isLoading">
          <OtherLoading />
        </div>
        <b-container class="no-gutters bg-white" v-else>
          <div class="pt-3">
            <b-row>
              <b-col md="6">
                <InputText
                  textFloat="Name"
                  placeholder="Name"
                  type="text"
                  name="promotionname"
                  isRequired
                  :v="
                    $v.form.promotion.news_language.$each.$iter[selectLanguage]
                      .name
                  "
                  :isValidate="
                    $v.form.promotion.news_language.$each.$iter[selectLanguage]
                      .name.$error
                  "
                  v-model="form.promotion.news_language[selectLanguage].name"
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Promotions Type <span class="text-danger">*</span></span
                  >
                </div>

                <InputSelect
                  title=""
                  name="Promotions Type"
                  isRequired
                  v-model="form.promotion.mobile_news_type_id"
                  :isValidate="$v.form.promotion.mobile_news_type_id.$error"
                  v-bind:options="promotionList"
                  valueField="id"
                  textField="name"
                  :noPleaseSelect="noPleaseSelect"
                  :disabled="isEdit"
                  :v="$v.form.promotion.mobile_news_type_id"
                  @onDataChange="handleChangeType"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <InputTextArea
                  textFloat="Short Description"
                  placeholder="Short Description"
                  isRequired
                  :value="
                    form.promotion.news_language[selectLanguage]
                      .short_description
                  "
                  :v="
                    $v.form.promotion.news_language.$each.$iter[selectLanguage]
                      .short_description
                  "
                  :isValidate="
                    $v.form.promotion.news_language.$each.$iter[selectLanguage]
                      .short_description.$error
                  "
                  v-model="
                    form.promotion.news_language[selectLanguage]
                      .short_description
                  "
                  rows="3"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <UploadFile
                      textFloat="Cover Image"
                      placeholder="Please Choose File"
                      format="all"
                      :fileName="form.promotion.image_url"
                      v-model="form.promotion.image_url"
                      name="thumbnail"
                      text="*Please upload only .png, .jpg image with ratio 1:1"
                      isRequired
                      :accept="acceptType"
                      :v="$v.form.promotion.image_url"
                      v-on:onFileChange="onImageChange"
                      :disabled="isDisable"
                      :isValidate="$v.form.promotion.image_url.$error"
                    />
                    <PreviewBox
                      :disabled="isDisable"
                      v-on:delete="deleteImage"
                      :showPreviewType="0"
                      :showPreview="showPreview"
                      :isLoadingImage="isLoadingImage"
                      :ratioType="1"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="mt-3 mb-3">
                <PreviewMultipleImages
                  v-on:deleteImages="deleteImagesList"
                  v-on:updateImageList="updateImageList"
                  :items="form"
                  :v="$v.form.promotion"
                />
              </b-col>
              <b-col md="12">
                <TextEditorsTiny
                  textFloat="Description"
                  :rows="4"
                  :value="
                    form.promotion.news_language[selectLanguage].description
                  "
                  :name="'description_' + 1"
                  placeholder="Type something..."
                  isRequired
                  :v="
                    $v.form.promotion.news_language.$each.$iter[selectLanguage]
                      .description
                  "
                  :isValidate="
                    $v.form.promotion.news_language.$each.$iter[selectLanguage]
                      .description.$error
                  "
                  @onDataChange="setValue"
                />
              </b-col>
              <b-col> </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.promotion.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.promotion.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="checkForm(0)" routePath="/promotionnews" />
      </b-form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import { required } from "vuelidate/lib/validators";
import InputTextArea from "@/components/inputs/InputTextArea";
import PreviewBox from "@/components/PreviewBox.vue";
import PreviewMultipleImages from "@/components/PromotionNews/PreviewMultipleImages.vue";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  name: "PromotionDetails",
  components: {
    InputText,
    InputSelect,
    PreviewMultipleImages,
    UploadFile,
    TextEditorsTiny,
    InputTextArea,
    OtherLoading,
    PreviewBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: true,
      isDisable: false,
      isEdit: false,
      noPleaseSelect: true,
      isLoadingImage: false,
      coverImgType: 1,
      acceptType: "image/jpeg, image/png",
      showPreview: "",
      promotionList: [],
      form: {
        promotion: {
          id: 0,
          mobile_news_type_id: 0,
          name: "",
          description: "",
          is_vedeo: "",
          image_url: "",
          status: 0,
          Base64String: "",
          imageList: [],
          news_language: [
            {
              id: 0,
              language_id: 1,
              mobile_news_id: 0,
              name: "",
              description: "",
              short_description: "",
            },
          ],
          short_description: "",
        },
      },
      image: {
        size: "",
        height: "",
        width: "",
      },
      checkrequired: {
        required: null,
      },
      deleteImageList: [],
      selectLanguage: 0,
    };
  },
  validations: {
    form: {
      promotion: {
        mobile_news_type_id: {
          required: requiredIf(function (item) {
            return item.mobile_news_type_id == 0 ? true : false;
          }),
        },
        image_url: { required },
        status: { required },
        news_language: {
          $each: {
            short_description: {
              required: requiredIf(function (item) {
                return item.language_id == 1 ? true : false;
              }),
            },
            name: {
              required: requiredIf(function (item) {
                return item.language_id == 1 ? true : false;
              }),
            },
            description: {
              required: requiredIf(function (item) {
                return item.language_id == 1 ? true : false;
              }),
            },
          },
        },
      },
    },
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    getDatas: async function () {
      this.isLoading = true;
      if (this.id !== "0") {
        await this.$store.dispatch("ActionGetNewByID", this.id);
        var data = await this.$store.state.moduleConnectAPI.stateNewByID;
        if (data.result == 1) {
          this.form.promotion = data.detail;
          if (data.detail.news_language.length > 0) {
            if (data.detail.news_language[0].name == "") {
              data.detail.news_language[0].name = this.form.promotion.name;
              data.detail.news_language[0].description =
                this.form.promotion.description;
              data.detail.news_language[0].short_description =
                this.form.promotion.short_description;
            }
          }
          if (data.detail.status === 0) {
            this.form.promotion.status = 0;
          } else {
            this.form.promotion.status = 1;
          }
          this.$v.form.$reset();
          if (this.form.promotion.id > 0) {
            this.isEdit = true;
            this.showPreview = this.form.promotion.image_url;
          }
        }
        this.changeLanguage(1);
        this.updateImageList(data.detail.imageList);
        this.GetNewsType();
      } else {
        this.GetNewsType();
      }
      this.isLoading = false;
    },
    async GetNewsType() {
      await this.$store.dispatch("ActionGetNewsType");
      var data = await this.$store.state.moduleConnectAPI.stateGetNewsType;
      if (data.result == 1) {
        this.promotionList = data.detail;
      }
    },
    handleChangeType: async function (value) {
      this.form.promotion.mobile_news_type_id = value;
    },
    handleStatus(value) {},
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    deleteImagesList(id) {
      this.deleteImageList.push(id);
    },
    updateImageList(list) {
      this.form.promotion.imageList = list;
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (parseInt(this.image.height) !== parseInt(this.image.width)) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            });
            this.deleteImage();
          }
        };
        img.src = await reader.result;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = reader.result;
        this.form.promotion.image_url = reader.result;
        this.form.promotion.Base64String = reader.result;
        this.form.promotion.is_vedeo = false;
      };
    },
    deleteImage() {
      this.form.promotion.image_url = null;
      this.form.promotion.Base64String = null;
      this.showPreview = null;
    },
    checkForm: async function (e) {
      this.$v.form.$touch();
      if (this.form.promotion.mobile_news_type_id === 0) {
        this.checkrequired.required = false;
      } else {
        this.checkrequired.required = true;
      }

      if (this.$v.form.$error) {
        return this.$refs.inputLang.setValue(1);
      }
      this.submit();
    },
    deleteImgList(id) {
      this.deleteImageList.push(id);
    },
    submit: async function () {
      this.$bus.$emit("showLoading");
      this.isDisable = true;
      let data = null;
      let payload = {
        ...this.form.promotion,
        name: this.form.promotion.news_language[0].name,
        short_description:
          this.form.promotion.news_language[0].short_description,
        description: this.form.promotion.news_language[0].description,
      };
      if (this.id !== "0") {
        this.form.promotion.deleteImageList = this.deleteImageList;
        await this.$store.dispatch("ActionUpdatetNews", payload);
        data = await this.$store.state.moduleConnectAPI.stateUpdatetNews;

        if (data.result == 1) {
          this.isDisable = false;
          this.successAlert().then(() => this.$router.push("/promotionnews"));
        } else {
          this.errorAlert(data.message);
        }
      } else {
        this.form.promotion.image_url = "";
        await this.$store.dispatch("ActionCreateNews", payload);
        data = await this.$store.state.moduleConnectAPI.stateCreateNews;
        if (data.result == 1) {
          this.isDisable = false;
          this.successAlert().then(() => this.$router.push("/promotionnews"));
        } else {
          this.errorAlert(data.message);
        }
      }
      this.$bus.$emit("hideLoading");
    },
    setValue(val) {
      this.form.promotion.news_language[this.selectLanguage].description = val;
    },
    async changeLanguage(val) {
      this.isLoading = true;

      if (
        this.form.promotion.news_language.length !=
        this.$refs.inputLang.languageList.length
      ) {
        // this.$nextTick(() => {
        for (const { id } of this.$refs.inputLang.languageList) {
          let index = this.form.promotion.news_language.find(
            (el) => el.language_id == id
          );
          if (!index)
            this.form.promotion.news_language.push({
              id: 0,
              language_id: id,
              member_level_id: 0,
              display: "",
              description: "",
            });
        }
        // });
      }

      await this.$nextTick();
      this.isLoading = false;
      let index = this.form.promotion.news_language.findIndex(
        (el) => el.language_id == val
      );
      this.selectLanguage = index;
    },
  },
};
</script>

<style scoped></style>
